import {
  Component,
  Input,
} from '@angular/core'

import { BehaviorSubject } from 'rxjs/BehaviorSubject'

import { FileType } from '@@types'

@Component({
  selector: 'cb-kyc-types-delete-confirm',
  templateUrl: './kyc-types-delete-confirm.component.html',
  styleUrls: ['./kyc-types-delete-confirm.component.scss']
})
export class KYCTypesDeleteConfirmComponent {
  @Input()
  public type: BehaviorSubject<FileType>
}
