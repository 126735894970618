import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { AlertModule } from '@alert/alert.module'
import { PanelModule } from '@panel/panel.module'

import { AccountOverviewComponent } from './components/account-overview/account-overview.component'

@NgModule({
  imports: [
    CommonModule,

    AlertModule,
    PanelModule,
  ],
  declarations: [
    AccountOverviewComponent,
  ],
  exports: [
    AccountOverviewComponent,
  ]
})
export class AccountOverviewModule { }
