import {
  Component,
  Input,
} from '@angular/core'

@Component({
  selector: 'cb-kyc-types-file-exists-warning',
  templateUrl: './kyc-types-file-exists-warning.component.html',
  styleUrls: ['./kyc-types-file-exists-warning.component.scss']
})
export class KYCTypesFileExistsWarningComponent {
  @Input()
  public fileCount: number
}
