import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { DropDownsModule } from '@progress/kendo-angular-dropdowns'
import { DatePickerModule } from '@progress/kendo-angular-dateinputs'
import { PopupModule } from '@progress/kendo-angular-popup'
import { TooltipModule } from '@progress/kendo-angular-tooltip'

import { CoreModule } from '@core/core.module'
import { PanelModule } from '@panel/panel.module'
import { ToolbarModule } from '@toolbar/toolbar.module'
import { SectionModule } from '@section/section.module'

import { StatusComponent } from '@sidebar/components/status/status.component'
import { QuicklinksComponent } from '@sidebar/components/quicklinks/quicklinks.component'
import { FlagsComponent } from '@sidebar/components/flags/flags.component'
import { RiskcodeComponent } from '@sidebar/components/riskcode/riskcode.component'
import { ActivityComponent } from '@sidebar/components/activity/activity.component'
import { StakeholderComponent } from '@sidebar/components/stakeholder/stakeholder.component'
import { TagsComponent } from '@sidebar/components/tags/tags.component'
import { ProfilesComponent } from '@sidebar/components/profiles/profiles.component'
import { MenuComponent } from '@sidebar/components/menu/menu.component'
import { RepresentativesComponent } from '@sidebar/components/representatives/representatives.component'
import { StakeholderPrimaryComponent } from '@sidebar/components/stakeholder-primary/stakeholder-primary.component'
import { SidebarComponent } from '@sidebar/components/sidebar/sidebar.component'
import { CategoryComponent } from './components/category/category.component'
import { PartnerComponent } from './components/partner/partner.component'

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,

    CoreModule,
    ToolbarModule,
    SectionModule,
    PanelModule,

    DropDownsModule,
    DatePickerModule,
    PopupModule,
    TooltipModule,
  ],
  declarations: [
    StatusComponent,
    CategoryComponent,
    QuicklinksComponent,
    FlagsComponent,
    RiskcodeComponent,
    ActivityComponent,
    StakeholderComponent,
    TagsComponent,
    ProfilesComponent,
    StakeholderPrimaryComponent,
    MenuComponent,
    RepresentativesComponent,
    StakeholderPrimaryComponent,
    SidebarComponent,
    PartnerComponent,
  ],
  exports: [
    StatusComponent,
    CategoryComponent,
    FlagsComponent,
    RiskcodeComponent,
    QuicklinksComponent,
    FlagsComponent,
    ActivityComponent,
    StakeholderPrimaryComponent,
    StakeholderComponent,
    TagsComponent,
    ProfilesComponent,
    RepresentativesComponent,
    SidebarComponent,
    PartnerComponent,
  ]
})
export class SidebarModule { }
