import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms'

import { DropDownsModule } from '@progress/kendo-angular-dropdowns'
import { GridModule } from '@progress/kendo-angular-grid'
import { InputsModule } from '@progress/kendo-angular-inputs'
import { TooltipModule } from '@progress/kendo-angular-tooltip'

import { CoreModule } from '@core/core.module'
import { ControlModule } from '@control/control.module'
import { DrawerModule } from '@drawer/drawer.module'
import { PanelModule } from '@panel/panel.module'
import { SectionModule } from '@section/section.module'
import { ToolbarModule } from '@toolbar/toolbar.module'
import { QuickviewModule } from '@quickview/quickview.module'
import { QuickActionsModule } from '@quick-actions/quick-actions.module'

import { KYCTypesComponent } from './pages/kyc-types/kyc-types.component'
import { LayoutComponent } from './pages/layout/layout.component'
import { KYCTypesDeleteConfirmComponent } from './components/kyc-types-delete-confirm/kyc-types-delete-confirm.component'
import { KYCTypesDrawerModule } from '@kyc-types-drawer/kyc-types-drawer.module'
import { KYCTypesQuickviewComponent } from './pages/kyc-types-quickview/kyc-types-quickview.component'
import { KYCTypesFileExistsWarningComponent } from './components/kyc-types-file-exists-warning/kyc-types-file-exists-warning.component'


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,

    DropDownsModule,
    GridModule,
    InputsModule,
    TooltipModule,

    CoreModule,
    ControlModule,
    DrawerModule,
    PanelModule,
    SectionModule,
    ToolbarModule,
    QuickviewModule,
    QuickActionsModule,

    KYCTypesDrawerModule,
  ],
  declarations: [
    KYCTypesComponent,
    KYCTypesDeleteConfirmComponent,
    KYCTypesFileExistsWarningComponent,
    KYCTypesQuickviewComponent,
    LayoutComponent,
  ],
  entryComponents: [
    KYCTypesFileExistsWarningComponent,
    KYCTypesDeleteConfirmComponent,
  ],
})
export class FileTypesSettingsModule { }
