import {
  Input,
  Component,
} from '@angular/core'

@Component({
  selector: 'cb-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {
  @Input()
  public pageTitle: string

  public constructor() {}
}
