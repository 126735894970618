import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms'

import { DropDownsModule } from '@progress/kendo-angular-dropdowns'
import { InputsModule } from '@progress/kendo-angular-inputs'

import { CoreModule } from '@core/core.module'
import { PanelModule } from '@panel/panel.module'
import { DrawerModule } from '@drawer/drawer.module'
import { ToolbarModule } from '@toolbar/toolbar.module'
import { SectionModule } from '@section/section.module'

import { CreateComponent } from './pages/create/create.component'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    InputsModule,
    DropDownsModule,

    CoreModule,
    PanelModule,
    DrawerModule,
    ToolbarModule,
    SectionModule,
  ],
  declarations: [
    CreateComponent,
  ],
  exports: [
    CreateComponent,
  ],
})
export class KYCTypesDrawerModule { }
