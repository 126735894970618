import { Injectable } from '@angular/core'
import { CanDeactivate } from '@angular/router'

import { StakeholderService } from '@core/services/stakeholder/stakeholder.service'

@Injectable({
  providedIn: 'root'
})
export class StakeholderClearViewGuard implements CanDeactivate<void> {
  public constructor(private service: StakeholderService) { }

  canDeactivate(): boolean {
    this.service.setView()
    this.service.setActiveType(null)
    this.service.setActiveId(null)

    return true
  }
}
